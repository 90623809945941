import { NextPage } from 'next'

import withAnonymousUserGuard from '@lib/withAnonymousUserGuard'

import { AccessMode, AccessPage } from '@components/AccessPage'

const SignInPage: NextPage = () => {
  return <AccessPage defaultAccessMode={AccessMode.SignIn} />
}

export default withAnonymousUserGuard(SignInPage)
